import React, { Component, createRef } from 'react';
import io from "socket.io-client";  
import PlayerSnake from './PlayerSnake';
import { easeLinear, scaleLinear, select as d3Select } from 'd3';


const socket = io('ferferehost.de:5000');
const width = 800;
const height = 800;
const grid = {width:100, height:100};
const xScale = scaleLinear().domain([0, grid.width]).range([0, width]);
const yScale = scaleLinear().domain([0, grid.height]).range([0, height]);
const COLORS = ['#FF0000', '#FFD700', '#00FF00', '#00FFFF', '#FF00FF','#FFF8DC','#FF8C00', '#FF1493']
class App extends Component {

  state = {
    name: '',
    color: '',
    joined: false,
    status: 'joined',
    players: [],
    foods: [],
    clientId: ''
  }
  constructor(props) {

    super(props); 
    this.gameControllContainer = createRef();
    this.onConnected = this.onConnected.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onJoined = this.onJoined.bind(this);
    this.onDied = this.onDied.bind(this);
  }

  componentDidMount(){

    socket.on('connected', this.onConnected);
    socket.on('update', this.onUpdate); 
    socket.on('joined', this.onJoined); 
    socket.on('youDied', this.onDied); 
  }

  componentWillUnmount() {

    socket.off('connected', this.onConnected);
    socket.off('update', this.onUpdate);
    socket.off('joined', this.onJoined); 
    socket.off('youDied', this.onDied); 
  }

  updateState(key, value) {
    this.setState({[key]:value});
  }
  joinGame() {
    const { color, name } = this.state;
    socket.emit('playerIsJoining',{color, name});
  }
  onDied() {
    this.setState({status: 'connected'})
  }

  onConnected(clientId) {
    this.setState({
      clientId,
      status: 'connected'
    })
  } 
  onUpdate({ players, foods }) {
    this.setState({
      players,
      foods
    });
  } 
  onJoined() {
    this.setState({  status: 'joined' })
    this.gameControllContainer.current.focus();
  }

  onKeyUp(e){ 
    if(this.state.status !== 'joined')
      return;
    switch(e.keyCode)
    {
        case 37: 
        case 39:   
        case 38:
        case 40: 
          socket.emit('keyPressed', e.keyCode) 
            break;
        default: return false;
    }  
  }
  
  renderFoods() { 
    return this.state.foods.map((food, key)=>{
        return <circle 
            key={key} 
            cx={xScale(food.x)} 
            cy={yScale(food.y)} 
            r={food.r}
            ref={node=>{
                d3Select(node).transition().ease(easeLinear).duration(50).attr('r', food.r)
            }}  
            fill="orange" 
          /> 
    })
  }
  renderPlayerSnakes() {
    return this.state.players.map((player, key)=>{
      return <PlayerSnake  
        key={key} 
        {...player} 
        xScale={xScale} 
        yScale={yScale}
        leading
        /> 
    })
  }
  renderLoading() {
    return (
      <div className="modal">
        <div className="loading">Loading, Please wait...</div>
      </div>
    )
  }
  renderCharacterSelection() {
    return (
      <div className="modal">
        <div className="character-selection">
          <strong>Welcome</strong>
          Please enter your name and pick a color. <br />
          <input type="text" value={this.state.name} onChange={e=>this.updateState('name',e.target.value)} placeholder="Nickname" />
          <ul>
            {COLORS.map((c,k)=>{
              return <li 
                className={c === this.state.color ? 'selected' : ''}
                key={k} 
                onClick={e=>this.updateState('color', c)} 
                style={{backgroundColor:c}} 
                />
            })}
          </ul>
          <hr />
          <button onClick={e=>this.joinGame()} disabled={this.state.name.length < 3 || this.state.color === ''}>Join</button>
        </div>
      </div>
    )
  }
  renderLeaderboard() {
    const playersSorted = this.state.players.sort((a,b)=> a.length > b.length);
    return (
      <div className="leaderboard"> 
        <div className="title">Leaderboard</div>
        <ul> 
        {
          playersSorted.map((player, k)=>{
            return <li><div className="box" style={{backgroundColor: player.color}} />{player.name} <span className="score">{player.length * 55}</span></li>
          })
        }
        </ul>
      </div>
    )
  }
  render() { 
    const status = this.state.status
    return (
      <div className="wrapper" onKeyUp={e=>this.onKeyUp(e)} tabIndex="0" ref={this.gameControllContainer}>
        <div className="svg-contaier">
          <svg width={width} height={height}> 

            {this.renderFoods()}
            {this.renderPlayerSnakes()} 
          </svg>
          {this.renderLeaderboard()}
        </div>
        {status === 'loading'   && this.renderLoading()}
        {status === 'connected' && this.renderCharacterSelection()}
      </div>
    )
  }
}

export default App;
