import React, { Component } from 'react';  
import { path as d3Path, select as d3Select , easeLinear} from 'd3'; 
  
class Snake extends Component {
     
    generatePathData() {
 
        const { xScale, yScale, points } = this.props;
        var path = d3Path();    
        path.moveTo(xScale(points[0].x), yScale(points[0].y));

        for(let i = 1; i < points.length; i++)
        {
            path.lineTo(
                xScale(points[i].x),
                yScale(points[i].y) 
            );
        }
         
        return path.toString();   
    }
 
    render() {  
        
        const { xScale, yScale, points } = this.props;
        return <g>
                
                
                <path 
                className="snake"  
                ref={node=>{
                    d3Select(node).transition().ease(easeLinear).duration(50).attr('d',this.generatePathData())
                }}  
                stroke={this.props.color}
                strokeWidth="6" 
                fill="none" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                />
                
                <circle r="5" fill={this.props.color} ref={node=>{
                    d3Select(node).transition().ease(easeLinear).duration(50)
                        .attr('cx', xScale(points[0].x))
                        .attr('cy', yScale(points[0].y))
                }}  />
                <g  
                    ref={node=>{
                        d3Select(node).transition().ease(easeLinear).duration(50)
                        .attr('transform', `translate(${xScale(points[0].x - 1.25)  }, ${yScale(points[0].y - 2.5)})`) 
                    }} >  
                    { this.props.leading &&  <g transform="scale(.15)  translate(5, -10)" >
                        <path d="m 64.000012,31.484944 28.902346,36.127871 28.902242,-36.127871 -7.22553,65.030147 -101.158086,0 L 6.1954237,31.484944 35.0977,67.612815 64.000012,31.484944 z" className="crown"/>}
                    </g>}
                </g>  
                
            </g>
    }
}

export default Snake;
